import React from 'react';
import { 
    PageContainer,
    PageHeader,
    PageTitle,
    PageDescription,
    PageImage,
    PageContent,
    PageContentIndent,
    PageSubtitle,
    Bold,
    Blue,
    BulletPointList,
    ListItem,
    RedirectLink,
    ButtonLink,
} from '../../NewsSectionElements';

import NewsData from '../../NewsData';


// NewsPage component
const News03Chambers = () => {
    
  // Find the news article with the matching ID
  const newsItem = NewsData.find(item => item.id === "news/stronghold-tech-join-chamber-of-commerce");


  return (
    <PageContainer>
      <PageHeader>
      <PageImage src={newsItem.image} alt={newsItem.alt} />
        <PageDescription>{newsItem.date}</PageDescription>
        <PageTitle><Blue>{newsItem.title}</Blue></PageTitle>
        <PageDescription>{newsItem.description}</PageDescription>
      </PageHeader>
      <br />
      <PageContent>
      In an exciting development for the local business community, Stronghold Tech <Bold>proudly announces its membership</Bold> in the esteemed <Bold>Black Country Chamber of Commerce</Bold>. This strategic alliance signals our commitment to fostering growth, innovation, and collaboration within the dynamic ecosystem of the Black Country region.
      </PageContent>
      <br />
      <PageSubtitle><Blue>Why Join the Chambers?</Blue></PageSubtitle>
      <br />
      <PageContent>
      At Stronghold Tech, we believe that <Bold>meaningful partnerships are key for sustainable growth</Bold>. By joining forces with the Black Country Chamber of Commerce, we aim to leverage the collective expertise, resources, and networks to drive our business forward and contribute to the thriving community.
      </PageContent>
      <PageContent>
      One of the key reasons behind our decision to join the Black Country Chamber of Commerce is the unparalleled <Bold>opportunities it presents for networking and relationship-building</Bold>. As a member, Stronghold Tech aligns itself with the diverse community of businesses, ranging from small enterprises to multinational corporations, across various industries. This expansive network serves as a fertile ground for forging new partnerships, exploring synergies, and uncovering potential business prospects.
      </PageContent>
      <PageContent>
      Furthermore, membership in the <Bold>Black Country Chamber of Commerce opens doors to a wealth of resources and support</Bold> services tailored to the needs of businesses at every stage of their journey. From access to training and development programmes to advocacy and lobbying initiatives, the Chambers offer a comprehensive suite of benefits designed to empower members and drive collective success.
      </PageContent>
      <PageContent>
        <Bold>Collaboration lies at the heart of our ethos at Stronghold Tech</Bold>. By engaging with fellow members of the Black Country Chamber of Commerce, we can utilise a diverse pool of perspectives, experiences, and ideas. This collaborative ethos not only enriches our own understanding but also fuels innovation and drives the development of new solutions to address the evolving needs of our clients and the wider community.
      </PageContent>
      <PageContent>
      Moreover, our membership in the Black Country Chamber of Commerce aligns seamlessly with our commitment to corporate social responsibility and community engagement. Through participation in chamber-led initiatives, events, and programmes, Stronghold Tech has the opportunity to make a positive impact beyond the boardroom, <Bold>contributing to the social and economic well-being of the Black Country region as a whole</Bold>.
      </PageContent>
      <br />
      <PageSubtitle><Blue>The benefits of joining the chambers</Blue></PageSubtitle>
      <br />
      <PageContent>
      The benefits of joining the Black Country Chamber of Commerce extend beyond the realm of business development and networking. As part of this influential business community, Stronghold Tech gains a platform to voice our support, concerns, and aspirations on issues that impact the local business landscape. Whether it's advocating for policies that support entrepreneurship, championing diversity and inclusion, or driving sustainability efforts, our membership empowers us to play an active role in shaping the future of the Black Country region.
      </PageContent>
      <PageContent>
      In addition to the tangible benefits, membership in the Black Country Chamber of Commerce also reinforces our credibility and reputation within the local business community. By aligning ourselves with an esteemed organisation that has a proven track record of supporting businesses and driving economic growth, we enhance our standing as a trusted and respected player in the market.
      </PageContent>
      <PageContent>
      As we embark on this new chapter as members of the Black Country Chamber of Commerce, Stronghold Tech remains committed to harnessing the power of collaboration, innovation, and community engagement to drive our business forward and create shared prosperity for all. We look forward to leveraging the myriad opportunities that this partnership affords and making meaningful contributions to the continued success and vibrancy of the Black Country region.
      </PageContent>
      
      <br />
      <br />
      <PageContent>
        <ButtonLink to="contact" offset={-80} spy={true} smooth={true}duration={500}
          >Get in touch</ButtonLink> today, and see how <Bold>Stronghold Tech</Bold> can Keep Your Business Safe Online.</PageContent>
    </PageContainer>
  );
};

export default News03Chambers;
