import React from "react";
import NewsNavbar from "../../News/NewsNavbar"
import News02Santander from "../../News/NewsSections/News02-Santander";
import Contact from "../../HomeSections/Contact";
import NewsSection from "../../HomeSections/News&Events"
import NewsFooter from "../../News/NewsFooter";
import CompanyFooter from "../../HomeSections/CompanyFooter";

const NewsPage02 = () => {
 
    return (
      <>
      <NewsNavbar />
      <News02Santander />
      <NewsSection />
      <Contact />
      <NewsFooter />
      <CompanyFooter />
      </>
    );
  };

  
export default NewsPage02;