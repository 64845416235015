import React from "react";
import Navbar from "../Navbar"
import Hero from "../HomeSections/Hero";
import About from "../HomeSections/About";
import Services from "../HomeSections/Services";
import Clients from "../HomeSections/Clients";
import Pricing from "../HomeSections/Pricing";
import NewsandEvents from "../HomeSections/News&Events"
import Contact from "../HomeSections/Contact";
import Footer from "../HomeSections/Footer";
import CompanyFooter from "../HomeSections/CompanyFooter";

const Home = () => {
 
    return (
      <><Navbar />
      <Hero />
      <About />
      <Services />
      <Clients />
      <Pricing />
      <NewsandEvents />
      <Contact />
      <Footer />
      <CompanyFooter />
      </>
    );
  };

  
export default Home;