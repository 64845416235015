import React from 'react';
import Slider from 'react-slick';
import StarRating from '../../../StarRating'; 
import EKN8 from "../../../../images/reviews/EK-N8.jpg";
import WestFC from "../../../../images/reviews/WestfieldsFC.png"
import C from "../../../../images/reviews/GoogleC.png"
import LO from "../../../../images/reviews/LO.png"
import {
  SliderContainer,
  Container,
  ClientImage,
  ClientDetails,
  CommentBox,
  Line
} from "./ClientSliderElements"

const TestimonialSlider = () => {
  // Testimonial data
  const testimonials = [
    { image: EKN8, name: "Ellen Kay", company: "Founder | NEURO 8",  quote: '"Engaging, helpful and hands on"', comment: 'The Stronghold team were engaging, helpful and hands on. My company works within the Defence and Security Sector so having a secure website and access to us is very important. Having scanned the site before the changes it was easy to know who to turn to and why it needed to change. Stronghold is a great name and a great team.', rating: 5},
    { image: WestFC, name: "Sean Edwards", company: "Westfields FC",quote: '"Improving Awareness"', comment: 'Westfields FC are pleased to promote our association with the Stronghold Tech team, those who attended our sponsors event will have met Stronghold and now understand how vulnerable we all are to Cyber Attacks whether its personal or through a business, we look forward to promoting Stronghold to all our members and sponsors over the coming season.', rating: 5},
    { image: LO, name: "Chandan Babra", company: "Director | Leominster Osteopaths",  quote: '"I’m excited to liaise further with Stronghold Tech..."', comment: 'I’ve just listened to one of the Directors, Phil present on the importance of cybersecurity. It’s fascinating to learn about the work they do, importance of cybersecurity for business development, risk and security awareness and so much more. I’m excited to liaise further with Stronghold Tech and ensure my business is as secure as possible.', rating: 5 },
    // Add more testimonial data as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Remove arrows
    // Add more settings as needed
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <Container key={index}>
            <CommentBox>
              <h3>{testimonial.quote}</h3>
              <StarRating rating={testimonial.rating} />
              <p>{testimonial.comment}</p>
            </CommentBox>
            <ClientImage src={testimonial.image} alt={`Client ${index + 1}`} />
            <ClientDetails>
              <h3>{testimonial.name}</h3>
              <Line/>
              <p>{testimonial.company}</p>
            </ClientDetails>
          </Container>
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default TestimonialSlider;
