import styled from 'styled-components';

export const ParallaxBackground = styled.div`
  position: relative;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 70vh;
  height: auto;
  padding: 20px 50px; /* Adjusted padding */

@media (max-width: 805px) {
  padding: 20px;
}
  `;

export const SectionTitle = styled.h2`
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-left: 50px;
  position: relative;
  font-family: 'Roboto', sans-serif;

  &::before {
    content: '';
    position: absolute;
    bottom: -8px; /* Adjust the distance of the line from the text */
    left: 0;
    width: 140px;
    height: 2px; /* Height of the blue line */
    background-color: #0953b3; /* Color of the blue line */
    // z-index: -1; /* Ensure the line is behind the text */
    border-radius: 5px; 
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -9.5px; /* Adjust the distance of the line from the text */
    left: 0;
    width: 69px; /* Width of the orange line */
    height: 5px; /* Height of the orange line */
    background-color: #fd7e14; /* Color of the orange line */
    // z-index: 1; /* Ensure the line is in front of the blue line */
    border-radius: 5px; 
  }
`;
