import React, { useState } from 'react';

// Import your pricing components here
import { 
    SectionTitle,
    Background,
    TabsContainer,
    Tab,
    PackageContainer,
    PackageBoxTitle,
    PackageBox,
    PricingBox,
    PricingText,
    PackageServices,
    CompareButton,
    ButtonLink,
} from './PricingElements';

// Tabs & Prices Data
const tabsData = [
  { id: 1, title: 'Cyber Bundles' },
  { id: 2, title: 'Phishing Bundles' },
  { id: 3, title: 'Secure Web Bundles' },
  { id: 4, title: 'Secure Hosting Bundles' },
];

const packagesData = [
  [ // Cyber Bundles
    {
      title: "BASIC",
      price: "190 pcm",
      services: ['Cyber Consultancy', 'Online Risk Profiling', 'Cyber Essentials'],
      isSelected: false,
    },
    {
      title: "SECURED",
      price: "280 pcm",
      services: ['BASIC BUNDLE +','Policy & Procedures', 'Insurance Compliance', 'Vulnerability Assessment', ''],
      isSelected: true,
    },
    {
      title: "ENHANCED",
      price: "910 pcm",
      services: ['SECURED BUNDLE +', 'Risk Assessment', 'Penetration Test', 'Dark Web Monitoring'],
      isSelected: false,
    }
  ],
  [ // Phishing Bundles
  {
    title: "FREE PHISH",
    price: '0.00 pcm/user',
    services: ['4 Month Trial', 'Platform Demo', '10 Staff Limit', 'Basic Templates', 'Basic Landing Pages'],
    isSelected: false,
  },
  {
    title: "SELF PHISH",
    price: '1.29 pcm/user',
    services: [ 'Unlimited Staff', '12 Months Access', 'Email Report Feature', 'Unrestricted Email Templates', 'Unrestricted Landing Page'],
    isSelected: true,
  },
  {
    title: "MANAGED PHISH",
    price: '2.15 pcm/user',
    services: ['Stronghold Tech Managed', 'Quarterly Reports', 'Structured Training', 'Advanced Phishing Tactics', 'Personallised Phishing Material'],
    isSelected: false,
  },
  ],
  [ // Secure Web Bundles
  {
    title: "BASIC",
    price: '860',
    services: ['Modern Responsive Design', 'Mobile and Desktop Friendly', 'Personalised Landing Page Template'],
    isSelected: false,
  },
  {
    title: "PREMIUM",
    price: '1,330',
    services: ['BASIC +', 'Bespoke Multi Page Designs', 'Extensive Customisation', 'Performance Optimisation'],
    isSelected: true,
  },
  {
    title: "ENHANCED",
    price: '2,200',
    services: ['Unique Design', 'Enhanced Functionality', 'E-Commerce'],
    isSelected: false,
  },
  ],
  [ // Secure Hosting Bundles
  {
    title: "BASIC",
    price: '60 pcm',
    services: ['SSL & Domain Name', 'Professional Email', 'Continuous Security Monitoring', 'Email Security Configuration'],
    isSelected: false,
  },
  {
    title: "SECURED",
    price: '80 pcm',
    services: ['Premium Email Features', 'Premium Security Features', 'Enhanced Performance & Resources', 'Continuous Performance Checks'],
    isSelected: true,
  },
  {
    title: "ENHANCED",
    price: '180 pcm',
    services: ['SECURED + ', 'Dedicated IP Address', 'Enhanced Email and File Storage', 'Optimal Performance & Resources '],
    isSelected: false,
  }
  ],
];

const Pricing = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const Package = ({ title, price, services, isSelected }) => {
    return (
      <PackageBox>
        <PricingBox isSelected={isSelected}>
          <PackageBoxTitle>{title}</PackageBoxTitle>
          <PricingText>From £{price}</PricingText>
        </PricingBox>
        <PackageServices>
          {services.map((service, index) => (
            <p key={index}>{service}</p>
          ))}
        </PackageServices>
        <ButtonLink
         to="contact" 
         offset={-80}
         spy={true}
         smooth={true}
         duration={500}>
        <CompareButton>Get In Touch</CompareButton>
        </ButtonLink>
      </PackageBox>
    );
  };

  return (
    <Background id='pricing'>
      <SectionTitle>OUR PRICING</SectionTitle>
      <TabsContainer>
        {tabsData.map((tab) => (
          <Tab 
            key={tab.id} 
            active={activeTab === tab.id} 
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
      <PackageContainer>
        {packagesData[activeTab - 1]?.map((packageData, index) => (
          <Package key={index} {...packageData} />
        ))}
      </PackageContainer>
    </Background>
  );
}

export default Pricing;
