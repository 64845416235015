
import logo from '../../../images/logos/SH-Nav-Logo.png';
import { animateScroll as scroll } from 'react-scroll';
import NewsBurgerMenu from '../../News/NewsBurgerMenu';
import {
    NavbarContainer,
    Logo,
    Menu,
    MenuItem,
    NavLink,
    NavLinkR,
    NavLink2,
} from "./NewsNavElements.js"


const MyNavbar = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <NavbarContainer >
        <Logo src={logo} alt="Logo"
        onClick={scrollToTop} />
      <NewsBurgerMenu />
      <Menu >
        <MenuItem>
          <NavLinkR
          onClick={scrollToTop}
          to="/"
          >HOME</NavLinkR>
        </MenuItem>
        <MenuItem>
          <NavLink to="news"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>NEWS</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink2 to="contact"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>CONTACT</NavLink2>
        </MenuItem>
      </Menu>
    </NavbarContainer>
  );
}

export default MyNavbar;
