
import logo from '../../images/logos/SH-Nav-Logo.png';
import { animateScroll as scroll } from 'react-scroll';
import BurgerMenu from '../BurgerMenu/index.js';
import {
    NavbarContainer,
    Logo,
    Menu,
    MenuItem,
    NavLink,
    NavLink2,
} from "./NavElements.js"


const MyNavbar = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <NavbarContainer >
        <Logo src={logo} alt="Logo"
        onClick={scrollToTop} />
      <BurgerMenu />
      <Menu >
        <MenuItem>
          <NavLink to="home" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>
              HOME</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="about" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            >ABOUT</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="services"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>SERVICES</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="clients"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>REVIEWS</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="pricing"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>PRICING</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="news"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>NEWS</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink2 to="contact"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>CONTACT</NavLink2>
        </MenuItem>
      </Menu>
    </NavbarContainer>
  );
}

export default MyNavbar;
