import React from "react";
import NewsNavbar from "../../News/NewsNavbar"
import News03FreeForSchools from "../../News/NewsSections/News03-FreeForSchools";
import Contact from "../../HomeSections/Contact";
import NewsSection from "../../HomeSections/News&Events"
import NewsFooter from "../../News/NewsFooter";
import CompanyFooter from "../../HomeSections/CompanyFooter";

const NewsPage03 = () => {
 
    return (
      <>
      <NewsNavbar />
      <News03FreeForSchools />
      <NewsSection />
      <Contact />
      <NewsFooter />
      <CompanyFooter />
      </>
    );
  };

  
export default NewsPage03;