import React from 'react';
import logo from '../../../images/logos/SH-Nav-Logo.png';
import {
  FooterWrapper,
  Text,
  Logo
} from "./ComapnyFooterElements"

// Footer component
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <Logo src={logo} alt="Stronghold Tech Logo" />
      <Text>All Rights Reserved ©{currentYear} Stronghold Tech Registered in England and Wales 14680725</Text>
      <br />
      <Text>Registered office address:<br /> University Of Wolverhampton Science Park, Technology Centre, Wolverhampton, West Midlands, England, WV10 9RU</Text>
    </FooterWrapper>
  );
};

export default Footer;
