import React from "react";
import NewsNavbar from "../../News/NewsNavbar"
import News04Chamber from "../../News/NewsSections/News04-Chambers";
import Contact from "../../HomeSections/Contact";
import NewsSection from "../../HomeSections/News&Events"
import NewsFooter from "../../News/NewsFooter";
import CompanyFooter from "../../HomeSections/CompanyFooter";

const NewsPage04 = () => {
 
    return (
      <>
      <NewsNavbar />
      <News04Chamber />
      <NewsSection />
      <Contact />
      <NewsFooter />
      <CompanyFooter />
      </>
    );
  };

  
export default NewsPage04;