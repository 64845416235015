import React from 'react';
import image from "../../../images/backgrounds/bg-hero.jpg"
import { 
    HeroContainer, 
    TextContent, 
    Title, 
    Paragraph, 
    OrangeButton,
    ButtonLink,
} from './HeroElements';

const Hero = () => {
  return (
    <HeroContainer id='home' imageUrl={image}>
      <TextContent>
        <Title>Enhance Your Digital Security</Title>
        <Paragraph>Protect your business, staff and clients. Create a new business culture that generates growth and opportunities.</Paragraph>
        <ButtonLink to="pricing"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}><OrangeButton>ARE YOU READY?</OrangeButton></ButtonLink>
      </TextContent>
    </HeroContainer>
  );
}

export default Hero;
