import styled from 'styled-components';

// Styled components for the footer
export const FooterWrapper = styled.footer`
  bottom: 0;
  left: 0;
  width: 100%;
  color: #999;
  background-color: #000;
  padding: 20px 0;
  text-align: center;
  padding-bottom: 50px;
`;

export const Logo = styled.img`
  max-width: 20%;
  height: auto;
  margin-bottom: 10px;
  
  @media screen and (max-width: 768px) {
  max-width: 50%;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
`;