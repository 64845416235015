import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import backgroundImage from '../../../images/backgrounds/bg-news2.jpg';
import NandESlider from './News&EventSlider';

import {
  ParallaxBackground,
  SectionTitle,
} from "./NewsElements"



const NewsandEvents = () => {
  return (
    <ParallaxBackground id="news" imageUrl={backgroundImage}>
      <SectionTitle>LATEST NEWS</SectionTitle>
      <NandESlider />
    </ParallaxBackground>
  );
};

export default NewsandEvents;
