import React from 'react';
import image from "../../../images/backgrounds/bg-light-about.png";
import how from "../../../images/icons/who.png";
import who from "../../../images/icons/what.png";
import what from "../../../images/icons/how.png";
import { 
    AboutContainer,
    SectionTitle,
    ItemContainer,
    Icon,
    Title,
    Text,
    Background
} from './AboutElements';

const About = () => {
  return (
    <Background id='about' imageUrl={image}>
    <SectionTitle>ABOUT US</SectionTitle>
    <AboutContainer >
      <ItemContainer>
        <Icon src={who} />
        <Title>Who We Are</Title>
        <Text>With a wealth of diverse cyber security knowledge and expertise, we are a team experienced in working with both private and public sectors. Our collective experience enables us to provide comprehensive cyber solutions that cater to the unique security needs of SMEs, safeguarding against cyber threats.</Text>
      </ItemContainer>
      <ItemContainer>
        <Icon src={what} />
        <Title>What We Do</Title>
        <Text>At Stronghold, we are committed to providing comprehensive cyber security services that empower companies to navigate the ever-evolving cyber threat landscape. Our expertise lies in mitigating cyber risk, fostering business continuity and supporting business growth, ensuring that organisations can thrive securely in today's digital landscape.</Text>
      </ItemContainer>
      <ItemContainer>
        <Icon src={how} />
        <Title>How We Do It</Title>
        <Text>Our client-centric approach and tailored packages include assessing your cyber security level, providing a clear roadmap for maturity, and delivering comprehensive, collaborative support for rapid progress towards enhanced cyber resilience.</Text>
      </ItemContainer>
    </AboutContainer>
    </Background>
  );
}

export default About;
