import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import PP from "../../../images/files/PrivacyPolicy.pdf"
import TC from "../../../images/files/tc.pdf"
import {
FooterContainer,
FooterContent,
FooterLink,
FooterLinkR,
FooterList,
FooterListItem,
FooterSection,
FooterTitle,
FooterA,
} from "./NewsFooterElements";


const Footer = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterTitle>SITE MAP</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterLinkR 
              onClick={scrollToTop} 
              to="/"
              >Home
            </FooterLinkR>
            </FooterListItem>
            <FooterListItem>
              <FooterLink 
              to="news" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>News
            </FooterLink></FooterListItem>
          </FooterList>
        </FooterSection>
        <FooterSection>
          <FooterTitle>CONTACT US</FooterTitle>
          <FooterList>
            <FooterListItem><FooterLink to="contact" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}>Web Form
            </FooterLink></FooterListItem>
            <FooterListItem><FooterA 
                href="https://calendly.com/stronghold-tech"
                target="_blank"
                rel="noopener noreferrer">Calendly</FooterA></FooterListItem>
            <FooterListItem><FooterA href={`mailto:${'protect@strongholdtech.co.uk'}`}>Email</FooterA></FooterListItem>
            <FooterListItem><FooterA 
            href="https://uk.linkedin.com/company/strongholdtech"
            target="_blank"
            rel="noopener noreferrer">LinkedIn</FooterA></FooterListItem>
          </FooterList>
        </FooterSection>
        <FooterSection>
          <FooterTitle>LEGAL</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterA 
                href={TC}
                target="_blank"
                rel="noopener noreferrer">Terms & Conditions</FooterA></FooterListItem>
            <FooterListItem>
              <FooterA 
                href={PP}
                target="_blank"
                rel="noopener noreferrer">Privacy Policy</FooterA></FooterListItem>
          </FooterList>
        </FooterSection>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
