import styled from 'styled-components';

export const StarContainer = styled.div`
font-size: 24px;
`;

export const Star = styled.span`
color: ${props => props.color || '#ffc107'};
`;

export const FullStar = styled(Star)`
&:after {
  content: "\\2605"; /* Unicode character for a filled star */
}
`;

export const HalfStar = styled(Star)`
&:after {
  content: "\\00BD"; /* Unicode character for a half-filled star */
}
`;

export const EmptyStar = styled(Star)`
&:after {
  content: "\\2606"; /* Unicode character for an empty star */
}
`;