import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

// Styled components
export const PageContainer = styled.div`
  padding: 20px;
`;

export const PageImage = styled.img`
  max-width: 80%;
  margin-top: 100px;
  margin-bottom: 20px;
`;

export const PageHeader = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

export const PageTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
`;

export const PageSubtitle = styled.h2`
  margin-left: 80px;
  margin-right: 80px;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.6;
  
  
  @media screen and (max-width: 520px) {
    max-width: 95%;
      margin: auto;
      margin-bottom: 20px;
    }
`;

export const PageDescription = styled.p`
  font-size: 16px;
  color: #888;
`;

export const PageContent = styled.div`
  margin-left: 80px;
  margin-right: 80px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.6;
  
  @media screen and (max-width: 520px) {
    max-width: 95%;
      margin: auto;
      margin-bottom: 20px;
    }
`;

export const PageContentIndent = styled.div`
  margin-left: 160px;
  margin-right: 160px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.6;
  
  @media screen and (max-width: 520px) {
    max-width: 95%;
      margin: auto;
      margin-bottom: 20px;
    }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Blue = styled.span`
  color: #0348B2;
`;

export const BulletPointList = styled.ul`
  line-height: 1.6;
  list-style-type: disc;
  padding-left: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
`;

export const RedirectLink = styled.a`
  color: #007bff; /* Initial color */
  font-weight: bold;
  text-decoration: none;
  position: relative;
  font-size: 14px;
  cursor: pointer;

  &::after {
   content: '';
   position: absolute;
   left: 0;
   bottom: -2px;
   height: 2px;
   width: 0; /* Initial width */
   background-color: blue; /* Initial color */
   transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
   color: #fd7e14; /* Color on hover */
  }

  &:hover::after {
   width: 100%; /* Final width */
   background-color: blue; /* Color on hover */
  }

  &.active {
   color: #fd7e14; /* Change color for active link */
   &::after {
     width: 100%; /* Ensure full width for active link */
     background-color: white; /* Color for active link */
   }
  }

`;

export const ButtonLink = styled(ScrollLink)`
   color: #007bff; /* Initial color */
  font-weight: bold;
  text-decoration: none;
  position: relative;
  font-size: 18px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0; /* Initial width */
    background-color: blue; /* Initial color */
    transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
    color: #fd7e14; /* Color on hover */
  }

  &:hover::after {
    width: 100%; /* Final width */
    background-color: blue; /* Color on hover */
  }

  &.active {
    color: #fd7e14; /* Change color for active link */
    &::after {
      width: 100%; /* Ensure full width for active link */
      background-color: white; /* Color for active link */
    }
  }

`;