import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
 BurgerButton,
 Menu,
 MenuItem,
 NavLink,
 NavLinkR,
 NavLink2,
} from "./BurgerMenuElements"


const NewsBurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const homeClick = () => {
    closeMenu()
    scrollToTop()
  }

  return (
    <>
      <BurgerButton onClick={toggleMenu}>☰</BurgerButton>
      <Menu open={isOpen}>
      <MenuItem onClick={closeMenu}>
          <NavLinkR to="/" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={homeClick}
            >
              HOME</NavLinkR>
        </MenuItem>
        <MenuItem>
          <NavLink to="news"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >NEWS</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink2 to="contact"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}>CONTACT</NavLink2>
        </MenuItem>
      </Menu>
    </>
  );
};

export default NewsBurgerMenu;
