import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import backgroundImage from '../../../images/backgrounds/bg-clients.png';
import ClientSlider from './ClientSlider';

import {
  ParallaxBackground,
  SectionTitle,
} from "./ClientsElements"



const TestimonialSection = () => {
  return (
    <ParallaxBackground id="clients" imageUrl={backgroundImage}>
      <SectionTitle>OUR REVIEWS</SectionTitle>
      <ClientSlider />
    </ParallaxBackground>
  );
};

export default TestimonialSection;
