import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import NewsPage01 from "./components/pages/news/News01-Launch";
import NewsPage02 from "./components/pages/news/News02-Santander";
import NewsPage03 from "./components/pages/news/News03-FreeForSchools";
import NewsPage04 from "./components/pages/news/News04-Chambers";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/news/stronghold-tech-official-launch" element={<NewsPage01 />} />
        <Route path="/news/Innovation-Winners" element={<NewsPage02 />} />
        <Route path="news/free-cyber-training-for-school-staff" element={<NewsPage03 />} />
        <Route path="news/stronghold-tech-join-chamber-of-commerce" element={<NewsPage04 />} />
        
      </Routes>
    </Router>
  );
}

export default App;