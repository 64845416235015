import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

export const BurgerButton = styled.button`
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 20px;

  @media (min-width: 897px) {
    display: none; /* Hide the burger button on larger screens */
  }
`;

export const Menu = styled.ul`
  list-style-type: none;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  background-color: #000;
  z-index: 1;
  height: ${props => (props.open ? '53vh' : '0')};
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease-in;

  @media (min-width: 897px) {
    display: flex;
    justify-content: center;
    position: static;
    background-color: transparent;
    border: none;
    padding: 0;
    top: auto;
    height: auto;
  }
`;

export const MenuItem = styled.li`
  margin: 0 20px;

  @media (max-width: 897px) {
    margin: 10px 0;
  }
`;

export const NavLinkR = styled(LinkR)`
  color: #999; /* Initial color */
  text-decoration: none;
  position: relative;
  font-size: 13px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0; /* Initial width */
    background-color: grey; /* Initial color */
    transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
    color: white; /* Color on hover */
  }

  &:hover::after {
    width: 100%; /* Final width */
    background-color: white; /* Color on hover */
  }

  &.active {
    color: white; /* Change color for active link */
    &::after {
      width: 100%; /* Ensure full width for active link */
      background-color: white; /* Color for active link */
    }
  }

`;

export const NavLink = styled(ScrollLink)`
  color: #999;
  text-decoration: none;
  position: relative;
  font-size: 13px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0;
    background-color: grey;
    transition: width 0.5s ease, background-color 0.3s ease;
  }

  &:hover {
    color: white;
  }

  &:hover::after {
    width: 100%;
    background-color: white;
  }

  &.active {
    color: white; /* Change color for active link */
    &::after {
      width: 100%; /* Ensure full width for active link */
      background-color: white; /* Color for active link */
    }
  }
`;

export const NavLink2 = styled(ScrollLink)`
  color: #fd7e14;
  text-decoration: none;
  position: relative;
  font-size: 13px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0;
    background-color: grey;
    transition: width 0.5s ease, background-color 0.3s ease;
  }

  &:hover {
    color: fd7e14;
  }

  &:hover::after {
    width: 100%;
    background-color: white;
  }

  &.active {
    color: #fd7e14; /* Change color for active link */
    &::after {
      width: 100%; /* Ensure full width for active link */
      background-color: white; /* Color for active link */
    }
  }
`;