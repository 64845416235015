import styled from 'styled-components';

export const Background = styled.div`
background-image: url(${props => props.imageUrl});
background-size: cover;
background-position: center;
padding: 20px 50px; /* Adjusted padding */
min-height: 85vh;
height: auto;
`;

export const AboutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  color: white;
  
  @media (max-width: 905px) {
    flex-direction: column;
    align-items: center;
    // padding: 20px;
  }
`;

export const SectionTitle = styled.h2`
color: #000;
font-size: 30px;
font-weight: 400;
margin-bottom: 20px;
margin-left: 50px;
position: relative;
font-family: 'Roboto', sans-serif;

&::before {
  content: '';
  position: absolute;
  bottom: -8px; /* Adjust the distance of the line from the text */
  left: 0;
  width: 140px;
  height: 2px; /* Height of the blue line */
  background-color: #0953b3; /* Color of the blue line */
  z-index: -1; /* Ensure the line is behind the text */
  border-radius: 5px; 
}

&::after {
  content: '';
  position: absolute;
  bottom: -9.5px; /* Adjust the distance of the line from the text */
  left: 0;
  width: 23px; /* Width of the orange line */
  height: 5px; /* Height of the orange line */
  background-color: #fd7e14; /* Color of the orange line */
  z-index: 1; /* Ensure the line is in front of the blue line */
  border-radius: 5px; 
}
`;

export const ItemContainer = styled.div`
  flex: 1;
  text-align: center;
  margin: 20px; /* Added margin */
  
  @media (max-width: 905px) {
    margin-bottom: 30px; /* Adjusted margin for smaller screens */
    margin-left: 0;
    margin-right:0;
  }
`;

export const Icon = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`;

export const Title = styled.h4`
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  color: #666;
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
`;
