import styled from 'styled-components';

export const Background = styled.div`
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
  padding: 20px 50px; /* Adjusted padding */
  font-family: 'Roboto', sans-serif;
  min-height: 90vh;
  height: auto;
`;

export const SectionTitle = styled.h2`
  color: #000;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-left: 50px;
  position: relative;
  font-family: 'Roboto', sans-serif;

  &::before {
    content: '';
    position: absolute;
    bottom: -8px; /* Adjust the distance of the line from the text */
    left: 0;
    width: 140px;
    height: 2px; /* Height of the blue line */
    background-color: #0953b3; /* Color of the blue line */
    z-index: -1; /* Ensure the line is behind the text */
    border-radius: 5px; 
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -9.5px; /* Adjust the distance of the line from the text */
    left: 0;
    width: 138px; /* Width of the orange line */
    height: 5px; /* Height of the orange line */
    background-color: #fd7e14; /* Color of the orange line */
    z-index: 1; /* Ensure the line is in front of the blue line */
    border-radius: 5px; 
  }
`;

export const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormContainer = styled.div`
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormInputs = styled.div`
  display: flex;
  flex-direction: column; /* Stack inputs vertically */
  align-items: flex-start; /* Align items to the left */
  width: 90%;
`;

export const NameInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NameInput = styled.input`
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 48%;
  font-family: 'Roboto', sans-serif;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 92%;
  font-family: 'Roboto', sans-serif;
`;

export const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 94%;
  font-family: 'Roboto', sans-serif;
`;

export const SubmitButton = styled.button`
  background-color: #fd7e14;
  color: white;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
  margin-top: 10px;
  margin-left: 10px; /* Add margin to separate from other buttons */

  &:hover {
    transform: translateY(-3px); /* Rise up on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adding shadow on hover */
  }

  @media screen and (min-width: 768px) {
    width: 50%; /* Making the button 50% width on larger screens */
  }
`;

export const TermsAndConditions = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
`;

export const BookCallButton = styled.a`
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px; /* Add margin to separate from other buttons */
  text-decoration: none; /* Remove default underline */

  &:hover {
    transform: translateY(-3px); /* Rise up on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adding shadow on hover */
  }

  @media screen and (min-width: 768px) {
    width: 50%; /* Making the button 50% width on larger screens */
  }
`;

export const EmailButton = styled.a`
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
  margin-top: 10px;
  margin-left: 10px; /* Add margin to separate from other buttons */
  text-decoration: none; /* Remove default underline */

  &:hover {
    transform: translateY(-3px); /* Rise up on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Adding shadow on hover */
  }

  @media screen and (min-width: 768px) {
    width: 50%; /* Making the button 50% width on larger screens */
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TC = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  margin-left: 20px;
`;

export const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  font-size: 16px;
  margin: 20px;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-size: 16px;
  margin: 20px;
`;
