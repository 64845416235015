import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Variables
const boxShadowColor = 'rgba(0, 0, 0, 0.1)';
const borderColor = '#ccc';
const borderRadius = '10px';

export const SliderContainer = styled.div`
  margin: 0 auto;
  margin-Top: 50px;
  max-width: 90%;

  
  @media screen and (max-width: 520px) {
  max-width: 95%;
    margin: auto;
  }
`;

export const Container = styled.div`
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
`;

export const DisplayBox = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: auto;
  border: 1px solid ${borderColor};
  border-radius: ${borderRadius};
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px ${boxShadowColor};
    transform: translateY(-5px);
  }



  @media screen and (max-width: 1420px) {
    width: 60%;
    margin: auto;
  }

  @media screen and (max-width: 520px) {
    width: 75%;
    margin-right: 60px;
  }
`;

export const DisplayBoxImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: ${borderRadius};
  
  @media screen and (max-width: 1010px) {
   max-width: 70%;
  }
`;

export const DisplayBoxTitle = styled.h2`
  font-size: 26px;
  margin: 20px auto 20px;
  text-align: center;
`;

export const DisplayBoxCategory = styled.h2`
  font-size: 12px;
  text-align: left;
`;

export const DisplayBoxDate = styled.h3`
  font-size: 12px;
  text-align: left;
`;

export const DisplayBoxText = styled.p`
  margin: 0;
  // font-weight: bold;
`;

export const ReadMoreLink = styled(Link)`
  display: inline-block;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  margin-Top: 20px;
  padding: 8px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;