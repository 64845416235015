import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

export const HeroContainer = styled.div`
  display: flex;
  min-height: 90vh;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
  font-family: 'Roboto', sans-serif;
  color: white; /* Set text color to white for better contrast */


  @media (max-width: 805px) {
    padding: 10px;
  }
`;

export const TextContent = styled.div`
  flex: 1;
  margin-right: 50px;
  padding-left: 50px;

  @media (max-width: 805px) {
    margin-top: 50px;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 400; /* Lighter font weight */

`;

export const Paragraph = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const ButtonLink = styled(ScrollLink)`

`;

export const OrangeButton = styled.button`
  padding: 15px 25px;
  background-color: #ff8700;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  transition: all 0.3s ease-in-out;
  
  &:hover {
    background-color: #0056b3;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;
