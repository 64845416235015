import styled from 'styled-components';

export const Background = styled.div`
background-colur: white;
background-size: cover;
background-position: center;
padding: 20px 50px; /* Adjusted padding */
font-family: 'Roboto', sans-serif;


@media (max-width: 805px) {
  padding: 10px;
}
`;

export const SectionTitle = styled.h2`
color: #000;
font-size: 30px;
font-weight: 400;
margin-bottom: 20px;
margin-left: 50px;
position: relative;
font-family: 'Roboto', sans-serif;

&::before {
  content: '';
  position: absolute;
  bottom: -8px; /* Adjust the distance of the line from the text */
  left: 0;
  width: 140px;
  height: 2px; /* Height of the blue line */
  background-color: #0953b3; /* Color of the blue line */
  z-index: -1; /* Ensure the line is behind the text */
  border-radius: 5px; 
}

&::after {
  content: '';
  position: absolute;
  bottom: -9.5px; /* Adjust the distance of the line from the text */
  left: 0;
  width: 46px; /* Width of the orange line */
  height: 5px; /* Height of the orange line */
  background-color: #fd7e14; /* Color of the orange line */
  z-index: 1; /* Ensure the line is in front of the blue line */
  border-radius: 5px; 
}
`;

export const TextContent = styled.div`
flex: 1;
margin: auto;
padding: 10px;

`;

export const TextContent2 = styled.div`
  flex: 1;
  margin: auto;
  padding: 10px;

  border-radius: 20px 20px 0 0; /* Add rounded top edges */
  text-align: center; /* Center the text horizontally */
`;


export const Paragraph = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const Paragraph2 = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const TabsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 20px; /* Set the gap between tabs */

  @media (max-width: 805px) {
    display: grid;
    justify-content: center; /* Center the tabs horizontally */
    width: 100%; /* Ensure full width */
  }
`;

export const Tab = styled.div`
font-size: 26px;
padding: 10px 20px;
cursor: pointer;
background-color: ${({ active }) => (active ? '#007bff' : '#fff')};
color: ${({ active }) => (active ? '#fff' : '#555')};
border-radius: 20px 20px 0 0; /* Add rounded top edges */
text-align: center; /* Center the text horizontally */
transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition effect */
position: relative;
outline: ${({ active }) => (active ? '2px solid #007bff' : '2px solid #555')};

/* Add trapezoid shape */
  &:before {
    content: '';
    position: absolute;
    bottom: -2px; /* Adjust distance from bottom */
    left: 20%;
    right: 20%;
    height: 2px; /* Adjust height of tab */
    background-color: ${({ active }) => (active ? '#007bff' : '#fff')};
    transform: perspective(5px) rotateX(5deg); /* Adjust perspective for trapezoid effect */
  }
  
  /* Add outline effect on hover */
  &:hover {
    outline: 2px solid #007bff;
    color: ${({ active }) => (active ? '#fff' : '#fd7e14')};
  }

  /* Change text color when clicked */
  &:active {
    color: #007bff;
  }
`;


export const CardsContainer = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center; /* Center the cards horizontally */

  @media (max-width: 805px) {
    flex-direction: column; /* Change to vertical layout on screens under 805px */
    align-items: center; /* Center the cards vertically on screens under 805px */
  }
`;

export const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  align-text: center;
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  flex: 0 0 calc(33.33% - 20px); /* Adjust width to fit three cards in a row */
  max-width: calc(33.33% - 20px); /* Adjust max width to fit three cards in a row */
  box-sizing: border-box; /* Include border and padding in the width calculation */
  transition: all 0.3s ease-in-out; /* Add transition for smoother hover effect */
  height: ${({ isVisible }) => (isVisible ? 'auto' : '100px')};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
    transform: translateY(-5px); /* Add slight translateY on hover for elevation effect */
    h3 {
      color: #0056b3; /* Change the color to your desired color */
    }
  }

  @media (max-width: 805px) {
    width: 80%;
    flex: 0 0 100%; /* Take up full width on screens under 805px */
    max-width: 100%; /* Take up full width on screens under 805px */
  }
`;

export const CardIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const CardTitle = styled.h3`
  font-size: 18px;
  // margin-top: 10px;
`;

export const CardText = styled.p`
  font-size: 14px;
  margin-top: 10px;
`;