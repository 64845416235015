  // Imported images
import Rocket from "../../../images/news&blogs/BlueRocket.jpg"
import Santander from "../../../images/news&blogs/Santander.svg"  
import SchoolComputers from "../../../images/news&blogs/Schools.png"
import chamber from "../../../images/news&blogs/ChamberLogo.png"    
import BCCC from "../../../images/news&blogs/BCCCLogo.png"

  // News data
const NewsData = [

  // Joining the Chambers May 24
  { id: "news/stronghold-tech-join-chamber-of-commerce",
  category: "NEWS",
  image: BCCC,
  alt: "Black Country Chambers of Commerce Logo",
  date: "May 24",
  title: "Stronghold Tech Joins Black Country Chamber of Commerce",
  description: "Stronghold Tech proudly announces its membership in the Black Country Chambers"},

    // Free training for schools March 24
    { id: "news/free-cyber-training-for-school-staff",
    category: "NEWS",
    image: SchoolComputers,
    alt: "Row of drawn computer screens on desks",
    date: "March 24",
    title: "FREE Cyber Security Training For UK School Staff",
    description: "Stronghold Tech Offers Free Cyber Security Training Platform for UK State School Staff"},

      // Santander Seed Fund Comp Winners July 2023
    { id: "news/Innovation-Winners",
    category: "NEWS",
    image: Santander,
    alt: "Santander Universities Logo",
    date: "July 23",
    title: "Innovation Winners of Santander Competition",
    description: "Celebrating Innovation in Cyber Security: Stronghold Tech's Double Triumph "},

    // SH Launch Day June 2023
    { id: "news/stronghold-tech-official-launch",
    category: "NEWS", 
    image: Rocket,
    alt: "Blue digital rocket launching",
    date: "June 23",
    title: "Stronghold Tech Officially Launch",
    description: "Stronghold Tech is a unique cyber security service provider supporting professional services to enhance their digital security.",
    },

];

export default NewsData;