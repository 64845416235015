import styled, { css } from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

export const Background = styled.div`
background-color: white;
height: auto;
min-height: 80vh;
background-position: center;
padding: 20px 50px; /* Adjusted padding */
font-family: 'Roboto', sans-serif;

@media (max-width: 805px) {
  padding: 10px;
}
`;

export const SectionTitle = styled.h2`
color: #000;
font-size: 30px;
font-weight: 400;
margin-bottom: 20px;
margin-left: 50px;
position: relative;
font-family: 'Roboto', sans-serif;

&::before {
  content: '';
  position: absolute;
  bottom: -8px; /* Adjust the distance of the line from the text */
  left: 0;
  width: 140px;
  height: 2px; /* Height of the blue line */
  background-color: #0953b3; /* Color of the blue line */
  // z-index: -1; /* Ensure the line is behind the text */
  border-radius: 5px; 
}

&::after {
  content: '';
  position: absolute;
  bottom: -9.5px; /* Adjust the distance of the line from the text */
  left: 0;
  width: 92px; /* Width of the orange line */
  height: 5px; /* Height of the orange line */
  background-color: #fd7e14; /* Color of the orange line */
  z-index: 1; /* Ensure the line is in front of the blue line */
  border-radius: 5px; 
}
`;

export const TabsContainer = styled.div`
  display: flex;
  margin-top: 70px;
  gap: 30px; /* Set the gap between tabs */

  @media (max-width: 805px) {
    display: grid;
    justify-content: center; /* Center the tabs horizontally */
    width: 100%; /* Ensure full width */
    margin-bottom: 40px;
  }
`;

export const Tab = styled.div`
font-size: 26px;
padding: 10px 20px;
cursor: pointer;
background-color: ${({ active }) => (active ? '#007bff' : '#fff')};
color: ${({ active }) => (active ? '#fff' : '#555')};
border-radius: 20px 20px 0 0; /* Add rounded top edges */
text-align: center; /* Center the text horizontally */
transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition effect */
position: relative;
outline: ${({ active }) => (active ? '2px solid #007bff' : '2px solid #555')};

/* Add trapezoid shape */
  &:before {
    content: '';
    position: absolute;
    bottom: -2px; /* Adjust distance from bottom */
    left: 20%;
    right: 20%;
    height: 2px; /* Adjust height of tab */
    background-color: ${({ active }) => (active ? '#007bff' : '#fff')};
    transform: perspective(5px) rotateX(5deg); /* Adjust perspective for trapezoid effect */
  }
  
  /* Add outline effect on hover */
  &:hover {
    outline: 2px solid #007bff;
    color: ${({ active }) => (active ? '#fff' : '#fd7e14')};
  }

  /* Change text color when clicked */
  &:active {
    color: #007bff;
  }
`;

export const PackageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column; /* Stack packages vertically on smaller screens */
    justify-content: center; /* Center packages vertically */
    margin: auto; /* Center the container horizontally */
    width: 70%;
  }
`;
export const PackageTitle = styled.h2`
  margin-top: 60px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const PackageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  
  /* Apply different styles when the package is selected */
  ${({ isSelected }) =>
    isSelected &&
    css`
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    `}

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }

  @media screen and (max-width: 768px) {
    width: 100%; /* Set width to 100% for smaller screens */
    margin: 10px 0; /* Adjust margin for better spacing */
  }

`;

export const PricingBox = styled.div`
  background-color: ${({ isSelected }) => (isSelected ? '#007bff' : '#f0f0f0')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#000')};
  // background-color: ${({ theme }) => '#e9ecef'};
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  transition: all 0.3s ease-in-out;

  ${PackageBox}:hover & {
    background-color: ${({ theme }) => '#0056b3'};
    color: #fff;
  }
  `;

export const PricingText = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const PackageBoxTitle = styled.h3`
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const PackageServices = styled.div`
  margin-bottom: 20px;
`;

export const CompareButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  transition: all 0.3s ease-in-out;
  
  &:hover {
    background-color: #0056b3;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;

export const ButtonLink = styled(ScrollLink)`
`;
