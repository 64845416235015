import React from 'react';
import {
    StarContainer,
    FullStar,
    HalfStar,
    EmptyStar,
} from "./StarRatingElements"

const StarRating = ({ rating }) => {
  const roundedRating = Math.round(rating * 2) / 2;

  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= roundedRating) {
      stars.push(<FullStar key={i} />);
    } else if (i - 0.5 === roundedRating) {
      stars.push(<HalfStar key={i} />);
    } else {
      stars.push(<EmptyStar key={i} />);
    }
  }

  return <StarContainer>{stars}</StarContainer>;
};

export default StarRating;
