import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import data from "../../../News/NewsData/index"
import {
  SliderContainer,
  Container,
  DisplayBoxImage,
  DisplayBoxTitle,
  DisplayBoxCategory,
  DisplayBoxDate,
  DisplayBox,
  DisplayBoxText,
  ReadMoreLink,
} from "./N&ESliderElements"

const NandESlider = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1420, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1010, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const DisplayBoxes = ({ id, image, alt, title, category, date, description,}) => {
    return (
      <DisplayBox>
      <DisplayBoxCategory>{category}</DisplayBoxCategory>
      <DisplayBoxDate>{date}</DisplayBoxDate>
      <DisplayBoxImage src={image} alt={alt}></DisplayBoxImage>
          <DisplayBoxTitle>{title}</DisplayBoxTitle>
          <DisplayBoxText>{description}</DisplayBoxText>
          <ReadMoreLink
            onClick={scrollToTop}
            to={`/${id}`} 
          >Read More
          </ReadMoreLink>
      </DisplayBox>
    );
  };
  
  return (
    <SliderContainer>
      <Slider {...settings}>
        {data.map((item, index) => (
          <Container key={index}>
            <DisplayBoxes
            id={item.id}
            image={item.image}
            title={item.title}
            category={item.category}
            date={item.date}
            description={item.description} />
          </Container>
        ))}
      </Slider>
    </SliderContainer>
  );
};

export default NandESlider;
