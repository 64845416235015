import React, { useState } from 'react';
import {
 BurgerButton,
 Menu,
 MenuItem,
 NavLink,
 NavLink2,
} from "./BurgerMenuElements"


const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <>
      <BurgerButton onClick={toggleMenu}>☰</BurgerButton>
      <Menu open={isOpen}>
      <MenuItem onClick={closeMenu}>
          <NavLink to="home" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >
              HOME</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="about" 
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >
              ABOUT</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="services"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >SERVICES</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="clients"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >REVIEWS</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="pricing"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >PRICING</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="news"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}
            >NEWS</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink2 to="contact"
            offset={-80}
            spy={true}
            smooth={true}
            duration={500}
            onClick={closeMenu}>CONTACT</NavLink2>
        </MenuItem>
      </Menu>
    </>
  );
};

export default BurgerMenu;
