import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

export const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 40px 0;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FooterSection = styled.div`
  flex: 1;
  margin-bottom: 30px;
`;

export const FooterTitle = styled.div`
  font-size: 1rem;
  margin-bottom: 20px;
`;

export const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FooterListItem = styled.li`
  margin-bottom: 10px;
`;

export const FooterLink = styled(ScrollLink)`
color: #999; /* Initial color */
text-decoration: none;
position: relative;
font-size: 13px;
cursor: pointer;

&::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0; /* Initial width */
  transition: width 0.9s ease, background-color 0.3s ease; /* Transition effect for width and color */
}

&:hover {
  color: #fd7e14; /* Color on hover */
}

&:hover::after {
  width: 100%; /* Final width */
  background-color: white; /* Color on hover */
}
`;

export const FooterLinkR = styled(LinkR)`
color: #999; /* Initial color */
text-decoration: none;
position: relative;
font-size: 13px;
cursor: pointer;

&::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0; /* Initial width */
  transition: width 0.9s ease, background-color 0.3s ease; /* Transition effect for width and color */
}

&:hover {
  color: #fd7e14; /* Color on hover */
}

&:hover::after {
  width: 100%; /* Final width */
  background-color: white; /* Color on hover */
}
`;

export const FooterA = styled.a`
color: #999; /* Initial color */
text-decoration: none;
position: relative;
font-size: 13px;
cursor: pointer;

&::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0; /* Initial width */
  transition: width 0.9s ease, background-color 0.3s ease; /* Transition effect for width and color */
}

&:hover {
  color: #fd7e14; /* Color on hover */
}

&:hover::after {
  width: 100%; /* Final width */
  background-color: white; /* Color on hover */
}
`;