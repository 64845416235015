import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import image from "../../../images/backgrounds/bg-light-about.png";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Background,
  SectionTitle,
  ContactFormContainer,
  FormContainer,
  FormInputs,
  NameInput,
  NameInputContainer,
  TextArea,
  SubmitButton,
  Input,
  BookCallButton,
  EmailButton,
  ButtonContainer,
  TC,
  SuccessMessage,
  ErrorMessage,
} from './ContactElements';

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const fnameInputRef = useRef();
  const lnameInputRef = useRef();
  const emailInputRef = useRef();
  const subjectInputRef = useRef();
  const messageInputRef = useRef();
  const recaptchaRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (
      !fnameInputRef.current.value ||
      !lnameInputRef.current.value ||
      !emailInputRef.current.value ||
      !subjectInputRef.current.value ||
      !messageInputRef.current.value
    ) {
      setErrorMessage("Please fill out all fields.");
      return; // Exit function if any field is empty
    }

    // Check if reCAPTCHA is verified
    if (!isVerified) {
      setErrorMessage("Please verify reCAPTCHA.");
      return; // Exit function if reCAPTCHA is not verified
    }

    try {
      const response = await emailjs.send(
        "sh_service_efqnpcr",
        "stronghold_contact_form",
        {
          fname: fnameInputRef.current.value,
          lname: lnameInputRef.current.value,
          email: emailInputRef.current.value,
          subject: subjectInputRef.current.value,
          message: messageInputRef.current.value
        },
        "672QvUAw-HwqMMVbG"
      );

      console.log("Email sent successfully:", response);
      setSuccessMessage("Form submitted! A member of the team will be in touch soon.");
      setErrorMessage(null);
      resetFormFields();
    } catch (error) {
      console.error("Email send error:", error);
      setErrorMessage("Something went wrong, please try again.");
      setSuccessMessage(null);
      resetFormFields();
    }
  };

  const handleRecaptchaChange = (token) => {
    if (token) {
      setIsVerified(true);
      setErrorMessage(null);
    }
  };

  const resetFormFields = () => {
    fnameInputRef.current.value = "";
    lnameInputRef.current.value = "";
    emailInputRef.current.value = "";
    subjectInputRef.current.value = "";
    messageInputRef.current.value = "";
    setIsVerified(false);
  };

  return (
    <Background id='contact' imageUrl={image}>
      <SectionTitle>GET IN TOUCH</SectionTitle>
      <ContactFormContainer>
        <FormContainer >
          <FormInputs>
            <NameInputContainer>
              <NameInput type="text" placeholder="First Name" name="firstName" required ref={fnameInputRef} />
              <NameInput type="text" placeholder="Last Name" name="lastName" required ref={lnameInputRef} />
            </NameInputContainer>
            <Input type="email" placeholder="Email" name="email" required ref={emailInputRef} />
            <Input type="text" placeholder="Subject" name="subject" required ref={subjectInputRef} />
            <TextArea placeholder="Message" name="message" required ref={messageInputRef} />
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
           
            <ButtonContainer>
              <SubmitButton onClick={handleSubmit}>Send Message</SubmitButton>
              <BookCallButton
                href="https://calendly.com/stronghold-tech"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book A Call
              </BookCallButton>
              <EmailButton href={`mailto:${'protect@strongholdtech.co.uk'}`}>Send Email</EmailButton>
            </ButtonContainer>
            <TC>By clicking 'Send Message', you are giving consent for the details you have provided to be processed in accordance with our privacy policy.</TC>
            
            </FormInputs>
        </FormContainer><ReCAPTCHA
              ref={recaptchaRef}
              sitekey={"6LfSEnwpAAAAAOzhtP4aMNrB3ZpybZa94pqBeOAP"}
              theme="dark"
              size="compact"
              onChange={handleRecaptchaChange}
            />
      </ContactFormContainer>
    </Background>
  );
};

export default Contact;
