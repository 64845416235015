import React from 'react';
import { 
    PageContainer,
    PageHeader,
    PageTitle,
    PageDescription,
    PageImage,
    PageContent,
    PageContentIndent,
    PageSubtitle,
    Bold,
    Blue,
    BulletPointList,
    ListItem,
    RedirectLink,
    ButtonLink,
} from '../../NewsSectionElements';

import NewsData from '../../NewsData';


// NewsPage component
const News03FreeForSchools = () => {
    
  // Find the news article with the matching ID
  const newsItem = NewsData.find(item => item.id === "news/free-cyber-training-for-school-staff");


  return (
    <PageContainer>
      <PageHeader>
      <PageImage src={newsItem.image} alt={newsItem.alt} />
        <PageDescription>{newsItem.date}</PageDescription>
        <PageTitle><Blue>{newsItem.title}</Blue></PageTitle>
        <PageDescription>{newsItem.description}</PageDescription>
      </PageHeader>
      <br />
      <PageContent>
      Stronghold Tech is proud to announce a new initiative aimed at  <Bold>enhancing cyber security awareness among staff members</Bold> of <Bold>state schools across the UK</Bold>. In alignment with the <Bold>Department for Education (DfE) standards</Bold>, we are offering <Bold>free access</Bold> to our comprehensive cyber security <Bold>training platform</Bold>, ensuring that school <Bold>staff are equipped with the knowledge and skills</Bold> needed to protect sensitive information and mitigate cyber threats effectively.
      </PageContent>
      <br />
      <PageSubtitle><Blue>What the Department for Education (DfE) standards state.</Blue></PageSubtitle>
      <PageContent>The Department for Education (DfE) standards stipulate: </PageContent>
      <PageContent>"Staff who require access to your IT network must undergo basic cyber security training annually, which should be incorporated into the induction process for new staff." </PageContent>
      <PageContent>
      "This training should cover: 
      </PageContent>
      <PageContentIndent>
        <BulletPointList>
          <ListItem>Phishing</ListItem>
          <ListItem>Password Security</ListItem>
          <ListItem>Social Engineering</ListItem>
          <ListItem>Risks associated with removable storage media" </ListItem>
          </BulletPointList>
      </PageContentIndent>
      <PageContent>
          <RedirectLink
            href="https://www.gov.uk/guidance/meeting-digital-and-technology-standards-in-schools-and-colleges/cyber-security-standards-for-schools-and-colleges"
            target="_blank"
            rel="noopener noreferrer">
              https://www.gov.uk/guidance/meeting-digital-and-technology-standards-in-schools-and-colleges/cyber-security-standards-for-schools-and-colleges
              </RedirectLink>
      </PageContent>
      <PageContent>
      The DfE recommends schools to adhere to the following guidelines: 
      </PageContent>
      <PageContentIndent>
        <BulletPointList>
          <ListItem>Ensure at least one member of the governing body completes the training.</ListItem>
          <ListItem>Remain vigilant as training requirements may evolve in response to emerging cyber threats. </ListItem>
          </BulletPointList>
      </PageContentIndent>  
      <br />
      <PageSubtitle><Blue>How Stronghold Tech is helping schools.</Blue></PageSubtitle>
      <PageContent>
        One of the most significant cyber threats facing schools today is <Bold>phishing attacks</Bold>, which can lead to <Bold>data breaches of staff, students and their family's personal data.</Bold>
        </PageContent>
      <PageContent>
        Our training <Bold>platform includes simulated phishing email exercises</Bold>, allowing staff members to <Bold>practice identifying and reporting phishing attempts</Bold> in a safe and controlled environment. By raising awareness and providing <Bold>practical training</Bold>, we can significantly <Bold>reduce the risk of successful phishing attacks</Bold> and enhance the overall security posture of schools. 
      </PageContent>
      <PageContent>
      In addition to phishing awareness, our <Bold>training platform covers a wide range of cyber security topics</Bold>, including <Bold>password security</Bold>, <Bold>social engineering</Bold>, and <Bold>best practices</Bold> for protecting sensitive information. Staff members will have <Bold>access to interactive modules</Bold>, instructional videos, and real-time assessments, enabling users to <Bold>learn at their own pace</Bold> and track their progress effectively.
      </PageContent>
      <PageContent>
      Moreover, our platform fosters a <Bold>culture of continuous learning</Bold> and improvement, empowering staff members to <Bold>stay up to date with the latest cyber security trends</Bold> and best practices. <Bold>By equipping educators</Bold> with the knowledge and skills to protect themselves and their schools, we can <Bold>create a safer environment for students and staff</Bold> alike. 
      </PageContent>
      <PageContent>
        <Bold>Stronghold Tech</Bold> is committed to <Bold>supporting the educational sector</Bold> across the UK in their efforts to <Bold>enhance cyber security awareness</Bold>. Our <Bold>free cyber security training platform for school</Bold> staff is a testament to our dedication for making a positive impact and <Bold>fostering a culture of cyber security awareness</Bold> in schools across the UK. 
      </PageContent>
      <br />
      <PageContent>
      Together, with the support of the educational sector, we can <Bold>build a safer and more secure environment for all</Bold>. 
      </PageContent>
      
      <br />
      <br />
      <PageContent>
        <ButtonLink to="contact" offset={-80} spy={true} smooth={true}duration={500}
          >Get in touch</ButtonLink> today, and see how <Bold>Stronghold Tech</Bold> can Enhance Your Digital Security.</PageContent>
    </PageContainer>
  );
};

export default News03FreeForSchools;
