import styled from 'styled-components';

export const SliderContainer = styled.div`
  margin: 0 auto; /* Center the slider */
  max-width: 800px; /* Set maximum width for the slider */
  
`;

export const Container = styled.div`
    display: flex;
    justify-content: center; /* Center the comment boxes horizontally */
    align-items: center; /* Align items vertically */
    font-family: 'Roboto', sans-serif;
`;

export const CommentBox = styled.div`
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  color: #fff;
  max-width: auto;
  padding: 40px 34px 65px;
  clip-path: polygon(0 0, 100% 0, 100% 88%, 91% 88%, 92% 100%, 87% 88%, 0 88%);
  margin: 0px 10px;
`;

export const ClientImage = styled.img`
  max-width: 72px;
  border: 6px solid rgba(255, 255, 255, 0.1);
  margin-left: auto; /* Add margin to the left to push the image to the right */
`;

export const ClientDetails = styled.div`
  color: #fff;
  text-align: right; /* Align text to the right */
`;

export const Line = styled.div`
margin-left: auto;
content: '';
bottom: -8px; /* Adjust the distance of the line from the text */
left: 0;
width: 130px;
height: 2px; /* Height of the blue line */
background-color: #fd7e14; /* Color of the blue line */
z-index: -1; /* Ensure the line is behind the text */
border-radius: 5px; 
`;