import React, { useState } from 'react';

import { 
    SectionTitle,
    Background,
    TabsContainer,
    Tab,
    CardsContainer,
    Card,
    TextContent,
    TextContent2,
    Paragraph,
    Paragraph2,
    // CardIcon,
    CardTitle,
    CardText
} from './ServicesElements';

// Tabs & Cards Data
const tabsData = [
    { id: 1, title: 'Cyber Security', 
    text: "Our Cyber Security services cover a range of solutions to enhance your digital security. We tailor our services to your business needs, identifying your risks and implementing solutions. Check out our bundles and speak to one of our consultants to understand how we can support you.",
    cards: [
        { id: 1, icon: 'icon1.png', title: 'Cyber Consultancy', text: 'Stronghold offers expert cyber consultancy services to help businesses assess and enhance their cyber security posture. We implement robust security measures tailored to your specific needs.' },
        { id: 2, icon: 'icon1.png', title: 'Online Risk Profiling', text: 'Our online risk profiling services assist organisations in understanding their digital risk landscape. We conduct comprehensive assessments to identify potential threats and vulnerabilities in your online infrastructure.' },
        { id: 3, icon: 'icon1.png', title: 'Cyber Essentials', text: 'Stronghold helps you achieve Cyber Essentials certification by ensuring your organisation meets the necessary requirements and demonstrates a strong commitment to cyber security best practices.' },
        { id: 4, icon: 'icon1.png', title: 'Security Awareness Training', text: 'We provide security awareness training programmes that empower your employees to become the first line of defense against cyber threats. We educate your staff about the latest cyber risks and phishing techniques.' },
        { id: 5, icon: 'icon1.png', title: 'Vulnerability Assessment: Web', text: 'For our website vulnerability assessments, we employ a thorough examination to uncover vulnerabilities, misconfigurations, and potential entry points that attackers could exploit, safeguarding your online assets and ensuring a secure digital presence.' },
        { id: 6, icon: 'icon1.png', title: 'Vulnerability Assessment: Network', text: 'With our network vulnerability assessments, we conduct comprehensive evaluations to pinpoint weaknesses, misconfigurations, and potential access points that attackers might exploit within your network infrastructure.' },
        { id: 7, icon: 'icon1.png', title: 'Penetration Testing', text: 'Our skilled ethical hackers simulate real-world attack scenarios on Network, Web, Mobile & Wireless infrastructure to identify and exploit vulnerabilities, providing you with valuable insights to strengthen your defenses.' },
        { id: 8, icon: 'icon1.png', title: 'Policy & Procedures', text: 'Our experts cover various Policy & Procedures areas, including: acceptable use policies; password policies; incident response plans; disaster recovery strategies; and more. These policies provide clear guidelines for employees.' },
        { id: 9, icon: 'icon1.png', title: 'Insurance Compliance', text: 'We offer specialised assistance to ensure your organisation meets the necessary requirements to obtain appropriate insurance coverage by providing guidance on policy frameworks and compliance standards.' },
        { id: 10, icon: 'icon1.png', title: 'Risk Assessment', text: 'By conducting thorough assessments and analysing key risk indicators, we enable you to prioritise and address vulnerabilities effectively, minimising the likelihood of security incidents.' },
        { id: 11, icon: 'icon1.png', title: 'Dark Web Monitoring', text: 'Stronghold Tech monitors the dark web for any indications of compromised credentials, sensitive data leaks, or potential threats targeting your organisation. We help you to respond swiftly to any security incidents.' },
        { id: 12, icon: 'icon1.png', title: 'Disaster Recovery Plan', text: 'Prepare your business for the unexpected with our comprehensive Disaster Recovery Plan, ensuring minimal downtime and maximum resilience in the face of disasters. Safeguard your operations and data with our tailored approach, providing peace of mind and confidence in your Disaster Recovery Plan.' },
        { id: 13, icon: 'icon1.png', title: 'Business Continuity Plan', text: 'Ensure the uninterrupted flow of your business operations with our meticulous Business Continuity Plan, meticulously crafted to mitigate risks and uphold productivity during crises. From natural disasters to unforeseen disruptions, trust our expert team to devise a resilient strategy that safeguards your assets and sustains your competitive edge in challenging times.' },
        { id: 14, icon: 'icon1.png', title: 'Incident Response Plan', text: 'We specialise in crafting bespoke Incident Response Plans tailored for your business, designed for easy implementation, enabling swift and efficient actions in the event of security threats, ensuring seamless protection of your operations and assets.' },
        { id: 15, icon: 'icon1.png', title: 'Cyber Playbooks', text: 'Our Cyber Playbooks offer practical rehearsals for handling cyber incidents, providing hands-on simulations to prepare your team effectively.' },
        { id: 16, icon: 'icon1.png', title: 'And More...', text: 'Reach out to our friendly team for more details and for a tailored solution to enhance your cyber security.' },
    ] },
    { id: 2, title: 'Phishing Awareness',
    text: "Discover our Phishing Awareness services designed to equip your team with the knowledge and tools necessary to combat phishing attacks effectively. With Managed or Self-Managed Platform options, Engagement Analytics, and Advanced Phishing Tactics, we empower your business to stay vigilant and secure against malicious email threats.",
     cards: [
        { id: 1, icon: 'icon1.png', title: 'Managed or Self-Managed Platform', text: 'Choose the perfect phishing simulation solution with our Managed or Self-Managed Platform options. With Managed, we handle technical aspects, while Self-Managed gives you control. Boost your cyber security awareness effortlessly with our platform, Stronghold Phish.' },
        { id: 2, icon: 'icon1.png', title: 'Engagement Analytics', text: 'Gain actionable insights with our Engagement Analytics platform, available in Managed or Self-Managed options. Whether we handle the technicalities or you prefer autonomy, our platform ensures optimised engagement effortlessly.'},
        { id: 3, icon: 'icon1.png', title: 'Email Reporting Feature', text: 'The Email Reporting Feature is your key to understanding the impact of your email campaigns. It provides essential insights into reach, engagement, and conversions, guiding data-driven decisions. ' },
        { id: 4, icon: 'icon1.png', title: 'Advanced Phishing Tactics', text: 'Enhance your cyber security with our Managed platform\'s Advanced Phishing Tactics. Our realistic attacks prepare your team to combat sophisticated threats effectively, safeguarding your organisation against evolving risks.' },
        { id: 5, icon: 'icon1.png', title: 'Structured Training', text: 'Our Managed service provides Structured Training on phishing attacks, adapted to each user\'s level. This ensures everyone, at every level, gains valuable skills to enhance cyber security resilience.' },
        { id: 6, icon: 'icon1.png', title: 'Personalised Phishing Material', text: 'Our Managed service provides Personalised Phishing Material tailored to your business based on industry, activity, and engagement with third parties. This targeted content enhances your team\'s ability to identify and mitigate phishing risks effectively.' },
        { id: 7, icon: 'icon1.png', title: 'Email Security Configuration Checks', text: 'Our Managed service includes Email Security Configuration Checks to ensure your systems are optimally configured. We assess and enhance your email security settings to mitigate risks effectively, safeguarding your organisation\'s communications from real world phishing attack.' },
        { id: 8, icon: 'icon1.png', title: 'Quarterly Reports', text: 'Our Managed service features Quarterly Reports, providing concise insights into your cyber security status. These reports offer key metrics and actionable recommendations to bolster your resilience against phishing threats.' },
        { id: 9, icon: 'icon1.png', title: 'And More...', text: 'Discover how phishing awareness training can protect your business. Contact our team today and start your FREE trial.' },
    ] },
    { id: 3, title: 'Web Development & Hosting',
    text: "Discover our Secure Web Development & Hosting services, enhanced by Government frameworks and the Open Web Application Security Project (OWASP) standards. From stringent coding practices to continuous security audits, we ensure your website remains resilient against evolving threats. Experience peace of mind with our comprehensive security solutions, tailored to protect your digital investments.",
     cards: [
        { id: 1, icon: 'icon1.png', title: 'Modern Responsive Design', text: 'Unlock a user-centric browsing experience with our modern responsive design, intuitively crafted to adapt and optimise across various devices, enhancing user satisfaction and engagement.' },
        { id: 2, icon: 'icon1.png', title: 'Mobile and Desktop Friendly', text: 'Rest assured, our websites are meticulously crafted to be both mobile and desktop-friendly as a standard, ensuring a seamless user experience regardless of the device your visitors are using.' },
        { id: 3, icon: 'icon1.png', title: 'Personalised Landing Page Templates', text: 'Elevate your online presence with our personalised landing page templates, tailored to reflect your unique brand identity and capture the attention of your target audience with style and sophistication.' },
        { id: 4, icon: 'icon1.png', title: 'Bespoke Multi-page Designs', text: 'Distinguish your online presence with our bespoke multi-page designs, meticulously crafted to align with your brand\'s vision and deliver a tailored user experience that resonates with your audience across every page.' },
        { id: 5, icon: 'icon1.png', title: 'Free TLS', text: 'Secure your online presence effortlessly with our complimentary SSL certificates, ensuring that your website data remains encrypted and your visitors can browse with confidence, all at no extra cost.' },
        { id: 6, icon: 'icon1.png', title: 'Free Professional Email', text: 'Elevate your communication with our complimentary professional email service, offering you a polished and reliable means of correspondence that reinforces your brand identity without any additional expense.' },
        { id: 7, icon: 'icon1.png', title: 'Professional Email & Workspace', text: 'Optimize your workflow with our comprehensive professional email and workspace solution, encompassing file storage, calendar, contact book, and more. Seamlessly integrate essential tools into one platform, enhancing efficiency and organisation.' },
        { id: 8, icon:'', title: 'Secure Redesign', text:'Enhance your online presence with a comprehensive rebuild of your current website by our expert team. We prioritise security enhancements to strengthen your digital footprint and ensure a resilient online identity.',},
        { id: 9, icon:'', title: 'WordPress Migration', text:'Seamlessly transfer your website to our hosting solution for secure implementation and monitoring. Our expert team ensures a smooth transition, prioritising security measures to safeguard your online presence effectively.',},
        { id: 10, icon: 'icon1.png', title: 'Domain Included', text: 'Get started hassle-free with our all-inclusive packages, where your domain is included at no extra charge. Establish your online presence effortlessly and enjoy the convenience of having everything you need to launch your website seamlessly.' },
        { id: 11, icon: 'icon1.png', title: 'Website Health Checks', text: 'Ensure your website\'s peak performance with our routine website health checks. Our regular assessments keep your site in top condition, identifying and addressing any issues promptly to maintain optimal functionality and user experience.' },
        { id: 12, icon: 'icon1.png', title: 'Continuous Wesbiste Backups', text: 'Rest easy knowing your website is backed up continuously with our reliable service. With continuous website backups, your data is safeguarded around the clock, providing peace of mind and ensuring your online presence is always protected.' },
        { id: 13, icon: 'icon1.png', title: 'Continuous Security Patching and Analysis', text: 'Keep your website secure with our continuous security patching and analysis. Our proactive approach ensures that your website is regularly updated with the latest security patches, while ongoing analysis helps identify and mitigate potential vulnerabilities, keeping your online assets safe from threats.' },
        { id: 14, icon: 'icon1.png', title: 'Security Headers Configurations', text: 'Enhance your website\'s security posture with our meticulous security headers configurations. Our expert team implements and fine-tunes security headers to bolster your website\'s defenses against various online threats, ensuring robust protection for your digital assets.' },
        { id: 15, icon: 'icon1.png', title: 'Email Security Configuration', text: 'Secure your email communications with our robust DMARC, SPF, DKIM, and MTA-STS configurations. Our comprehensive setup ensures that your emails are authenticated, encrypted, and delivered reliably, bolstering your defenses against spam, phishing, and spoofing attacks while maintaining the integrity of your domain reputation.' },
        { id: 16, icon: 'icon1.png', title: 'Email Breach Monitoring', text: 'Monitor potential email breaches with our proactive service. We keep an eye out for any signs of compromised email credentials linked to your domain, alerting you promptly to protect your business communications and data.' },
        { id: 17, icon: 'icon1.png', title: 'And More...', text: 'Explore our comprehensive services and discover even more solutions tailored to your needs. Get in touch with our friendly team today for further details on how we can elevate your online presence and support your business goals.' },
    ] },
  ];


const Services = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [cardVisibility, setCardVisibility] = useState({});

  
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    // Reset card visibility state
    setCardVisibility({});
  };

  const toggleCardVisibility = (cardId) => {
    setCardVisibility((prevVisibility) => ({
      ...prevVisibility,
      [cardId]: !prevVisibility[cardId],
    }));
  };


  return (<>
    <Background id='services'>
    <SectionTitle>OUR SERVICES</SectionTitle>
    <TextContent>
      <Paragraph>All of our services fall under three key categories: Cyber Security, Phishing Awareness & Secure Web Development / Hosting.</Paragraph>
    </TextContent>
    <TabsContainer>
        {tabsData.map((tab) => (
          <Tab key={tab.id} active={activeTab === tab.id} onClick={() => handleTabClick(tab.id)}>
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
    <TextContent2>
        {tabsData.map((tab) => (
          activeTab === tab.id && (
          <Paragraph2 key={tab.id}>
            {tab.text}
          </Paragraph2>
          )
        ))}
    </TextContent2>
      {tabsData.map((tab) => (
        <CardsContainer key={tab.id} active={activeTab === tab.id}>
          {tab.cards.map((card) => (
            <Card
            key={card.id}
            isVisible={cardVisibility[card.id]}
            onClick={() => toggleCardVisibility(card.id)}
          >
            {/* <CardIcon src={card.icon} alt="Card Icon" /> */}
            <CardTitle>{card.title}</CardTitle>
            {cardVisibility[card.id] && <CardText>{card.text}</CardText>}
          </Card>
        ))}
      </CardsContainer>
      ))}
    </Background>
    </>
  );
}

export default Services;
