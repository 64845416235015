import React from 'react';
import { 
    PageContainer,
    PageHeader,
    PageTitle,
    PageDescription,
    PageImage,
    PageContent,
    PageContentIndent,
    PageSubtitle,
    Bold,
    Blue,
    ButtonLink,
} from '../../NewsSectionElements';

import NewsData from '../../NewsData';


// NewsPage component
const News01 = () => {
    
  // Find the news article with the matching ID
  const newsItem = NewsData.find(item => item.id === "news/stronghold-tech-official-launch");


  return (
    <PageContainer>
      <PageHeader>
      <PageImage src={newsItem.image} alt={newsItem.alt} />
        <PageDescription>{newsItem.date}</PageDescription>
        <PageTitle><Blue>{newsItem.title}</Blue></PageTitle>
        <PageDescription>{newsItem.description}</PageDescription>
      </PageHeader>
      <br />
      <PageContent>Just as your business is unique with its own individual concoction of products, services, and operations, so are your security requirements. Stronghold Tech thus provides a huge range of services <Bold>tailored to your business’ needs.</Bold></PageContent>
      <PageContent>Our goal is to Enhance Your Digital Security by protecting <Bold>your business, clients, and supply chain.</Bold></PageContent>
      <br />
      <PageSubtitle><Blue>What is a cyber security service provider?</Blue></PageSubtitle>
      <PageContent>A cyber security service provider <Bold>delivers a suite of specialized services</Bold> designed to mitigate and manage digital risks faced by businesses. These services encompass a wide array of proactive and reactive measures aimed at safeguarding businesses from a diverse range of cyber threats.</PageContent>
      
      <br />
      <PageSubtitle><Blue>How is Stronghold Tech different from other cyber security providers?</Blue></PageSubtitle>
      <PageContent>At Stronghold Tech, we operate as a <Bold>Managed Security Service Provider (MSSP)</Bold> allowing us to get to understand your business, how it operates, what's important, and most importantly where your risks lie.  </PageContent>
      <PageContent>We know that for many companies It is extremely difficult to know where to begin with cyber security. Understanding where to start and what to focus on first can be difficult, especially when you want to be sure you're implementing the correct measures for that moment in time. When you look for cyber security solutions, it may be difficult to understand the differences between providers and which service you need to prioritise first. At Stronghold Tech we map out your company’s cyber risks and help you to <Bold>roadmap a way forward to Enhanced Digital Security.</Bold></PageContent>
      <PageContent>Every business has three core elements, consisting of their <Bold> People, Processes, and Technology (PPT)</Bold>. Stronghold Tech reviews the risk you currently have involving your People, Processes, and Technology. We then categorise these types of risks, what potential they have on impacting your business, and what you can do to reduce or remove these risks. This approach helps protect businesses by identifying your individual risks and implementing appropriate measures across the whole of your organisation.</PageContent>
      <PageContent>To do this, we provide packages for our three core services covering all areas of <Bold>Cyber Security</Bold>, <Bold>Phishing Awareness Training,</Bold> and <Bold>Secure Web Development and Hosting.</Bold></PageContent>
      
      <br />
      <PageContentIndent><Bold><Blue>Cyber Security Services:</Blue></Bold></PageContentIndent>
      <PageContentIndent>Our cyber security packages allow businesses of all sizes to implement appropriate solutions to understand and reduce their risks. These services range from <Bold>Risk Assessments</Bold> and <Bold>Vulnerability Scans</Bold> to <Bold>Training</Bold> and <Bold>Workshops</Bold> to full-scale <Bold>Penetration Tests</Bold>. </PageContentIndent>
      <br />
      <br />
      <PageContentIndent><Bold><Blue>Phishing Awareness Training:</Blue></Bold></PageContentIndent>
      <PageContentIndent>Phishing emails are still a high-risk threat to all businesses. They are extremely common and vary in styles of attacks. With whatever defence you already have, once one lands in your inbox it’s up to you to identify and report it. Without <Bold>proactive</Bold> and <Bold>continuous training</Bold>, it becomes extremely difficult to identify attacks. Our unique training method takes a hands-on approach. Not only can you engage with video and questionnaire training, but our focus is on regular <Bold>Phishing Email Simulations</Bold>. Sending your team simulation emails regularly trains them to understand the different types of attacks and what to look for when identifying them. Our Phishing Awareness Training provides users with the skills to spot phishing emails and creates a <Bold>company culture, where vigilance and prompt reporting of phishing attacks are paramount.</Bold></PageContentIndent>
      <br />
      <br />
      <PageContentIndent><Bold><Blue>Secure Web Development and Hosting:</Blue></Bold></PageContentIndent>
      <PageContentIndent>We not only identify risks within web applications but also create <Bold>secure</Bold> and <Bold>bespoke solutions</Bold>. Whether it’s landing pages or e-commerce platforms, we leverage Government and Open Web Application Security Project (OWASP) frameworks to ensure a consistently high level of security throughout. Furthermore, our website and email managed-hosting plans feature <Bold>comprehensive security configurations</Bold>, with <Bold>continuous patching</Bold> and <Bold>compromise monitoring</Bold> as standard. </PageContentIndent>
      <PageContentIndent><Bold>Our hosting packages' high security standards are incomparable.</Bold></PageContentIndent>
      <br />
      <br />
      <PageContent>Visit <Bold>strongholdtech.co.uk</Bold> for a full list of our services and packages. Understand your business risks, improve training, and implement a secure digital presence to <Bold>Enhance Your Digital Security</Bold>.</PageContent>
      <PageContent>
        <ButtonLink to="contact" offset={-80} spy={true} smooth={true}duration={500}
          >Get in touch</ButtonLink> today by using the web form, emailing protect@strongholdtech.co.uk or booking a call, and see how <Bold>Stronghold Tech</Bold> can Enhance Your Digital Security.</PageContent>
    </PageContainer>
  );
};

export default News01;
