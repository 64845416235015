import React from 'react';
import { 
    PageContainer,
    PageHeader,
    PageTitle,
    PageDescription,
    PageImage,
    PageContent,
    Bold,
    Blue,
    ButtonLink,
} from '../../NewsSectionElements';

import NewsData from '../../NewsData';


// NewsPage component
const News02Santander = () => {
    
  // Find the news article with the matching ID
  const newsItem = NewsData.find(item => item.id === "news/Innovation-Winners");


  return (
    <PageContainer>
      <PageHeader>
      <PageImage src={newsItem.image} alt={newsItem.alt} />
        <PageDescription>{newsItem.date}</PageDescription>
        <PageTitle><Blue>{newsItem.title}</Blue></PageTitle>
        <PageDescription>{newsItem.description}</PageDescription>
      </PageHeader>
      <br />
      <PageContent>
      We are thrilled to announce that Stronghold Tech has been honoured with securing <Bold>second place</Bold> in the esteemed Santander Seed Fund competition 2023, <Bold>also winning the innovation category!</Bold> This remarkable dual triumph is a testament to our unwavering <Bold>dedication to innovation</Bold> and shaping the future of <Bold>cyber security</Bold>.
      </PageContent>
      <PageContent>
        At <Bold>Stronghold Tech</Bold>, we understand that true <Bold>cyber security goes beyond just protecting data</Bold>; it's about securing what makes up a business - its <Bold>people, processes, and technology</Bold>. With this holistic approach at the core of our mission, we are proud to be leading the charge in safeguarding businesses against the ever-evolving cyber threats of this digital age.
      </PageContent>
      <PageContent>
      Our focus on <Bold>securing the entirety of your business</Bold> is reflected in our key support offerings, which include managed services for businesses in <Bold>cyber security</Bold>, <Bold>phishing awareness training</Bold>, and <Bold>secure web development and hosting</Bold>. Through our managed services, we provide businesses with the proactive support and <Bold>expertise needed to detect, prevent, and respond to cyber threats</Bold> effectively.
      </PageContent>
      <PageContent>
      In addition, our <Bold>phishing awareness training</Bold> empowers employees with the knowledge and skills to identify and <Bold>mitigate phishing attacks</Bold>, which remain one of the most common and potent cyber threats facing businesses today. Furthermore, our <Bold>secure web development and hosting</Bold> services ensure that businesses can <Bold>establish and maintain a robust online presence</Bold> while prioritising security and data protection.
      </PageContent>
      <PageContent>
      This recognition serves as <Bold>validation of our innovative approach to cyber security</Bold>, setting the stage for even greater accomplishments in the future. As we celebrate this milestone, we are propelled by a renewed sense of purpose and determination to <Bold>continue pushing the boundaries of innovation in cyber security</Bold>.
      </PageContent>
      <PageContent>
      With the support of the Santander Seed Fund and the recognition for innovation, we are more committed than ever to making a positive impact on the world. Our journey of innovation and entrepreneurship is guided by a <Bold>steadfast commitment to protecting businesses, organisations, and individuals from the ever-evolving threats of the digital age</Bold>.
      </PageContent>
      <PageContent>
      As we look to the future, we remain steadfast in our resolve to <Bold>innovate, inspire, and lead the charge in transforming the landscape of cyber security</Bold>. Together, with the support of our dedicated team, partners, and supporters, we will continue to shape a safer and more secure digital world for generations to come.
      </PageContent>
      <br />
      <br />
      <PageContent>
        <ButtonLink to="contact" offset={-80} spy={true} smooth={true}duration={500}
          >Get in touch</ButtonLink> today, and see how <Bold>Stronghold Tech</Bold> can Enhance Your Digital Security.</PageContent>
    </PageContainer>
  );
};

export default News02Santander;
